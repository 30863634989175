<template>
  <div>
    <div id="view-header__teleport__single"></div>
    <div v-if="!notationRoutes.includes($route.name)">
      <div class="view-header view-header--protocol-details" v-if="pageTitle">
        <div class="view-header__header" v-if="Object.keys(task).length>0">
          <Btn
            v-if="!task.date_realisation
            && task?.type?.uid !== 'NOTATION'
            && helperService.userHasPermission('essaievenement_oedit')
            "
            text="Réaliser la tâche expérimentale"
            hollow
            color="white"
            icon="check"
            @click="modal.realizeEvent = true"
          />
          <div id="header-action"></div>
        </div>
        <div class="view-header__footer">
          <Btn
            v-if="$route.params.id"
            class="back-btn"
            round
            grow
            icon="arrow-left"
            :to="{
              name: 'essaiCalendar',
              params: {
                id: $route.params.id,
              },
            }"
          />
          <h1 class="page-title">
            <template v-if="!task?.designation">
              {{ task?.type?.designation }}
            </template>
            <template v-else>
              {{ task?.type?.designation }} - {{ task?.designation }}
            </template>
            <div class="page-subtitle">Essai :
              {{ essai?.numero ? `${essai?.numero} -` : '' }} {{ essai?.nom}}
            </div>
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="view-body">
    <Section class="section section--no-padding">
      <TabsMaterial
        v-if="Object.keys(task).length && !notationRoutes.includes($route.name)"
        :tabs="tabs"
      />
      <router-view
        v-if="Object.keys(task).length"
        :task="task"
        :essai="essai"
        :pageTitle="pageTitle"
        :isEditable="isEditable"
      ></router-view>
    </Section>
  </div>

  <!-- Modals realizeEvent -->
  <Modal
    title="Déclarer la réalisation de la tâche expérimentale&nbsp;?"
    :active="modal.realizeEvent"
    :data="modalData"
    @modal-close="modal.realizeEvent = false"
  >
    <template v-slot:modal-body>
      <Input
        v-model="realizationDate"
        required
        id="date_realisation"
        label="Date de réalisation"
        type="date"
        :options="{
          max: new Date().toISOString().split('T')[0]
        }"
      />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.realizeEvent = false" />
      <Btn
        text="Déclarer"
        @click="realizeEvent()"
        icon="check"
        color="primary"
      />
    </template>
  </Modal>

  <NavigationDropdownEssai />
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import TabsMaterial from '@/components/layout/TabsMaterial.vue'
import NavigationDropdownEssai from '@/views/componentsViews/navigationDropdown/NavigationDropdownEssai.vue'
import Modal from '@/components/layout/Modal.vue'
import Input from '@/components/form/Input.vue'

export default {
  name: 'ExperimentalTaskView',

  components: {
    Input,
    Modal,
    NavigationDropdownEssai,
    Btn,
    Section,
    TabsMaterial,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      isEditable: true,
      task: {},
      essai: {},
      notationRoutes: [
        'essaiExperimentalTaskVariableStudiedSettings',
        'essaiExperimentalTaskVariableStudiedEntryLevel',
      ],
      modal: {
        realizeEvent: false,
      },
      modalData: {},
      realizationDate: new Date().toISOString().split('T')[0],
    }
  },

  computed: {
    tabs() {
      return [
        {
          urlName: 'essaiExperimentalTaskGeneralInformation',
          text: 'Informations générales',
        },
        ...(this.task?.type?.uid === 'PRELEVEMENT'
          ? [{ urlName: 'essaiExperimentalTaskOrgan', text: 'Organe' }]
          : []), // Prélevement
        ...(this.task?.type?.uid === 'CONTAMINATION'
          ? [
            {
              urlName: 'essaiExperimentalTaskArtificialContamination',
              text: 'Produit contaminant',
            },
          ]
          : []), // Contamination artificielle
        ...(this.task?.type?.uid === 'TRAITEMENTFERTI'
          ? [
            {
              urlName: 'essaiExperimentalTaskFichePeseesFerti',
              text: 'Fiche de pesées',
            },
            {
              urlName: 'essaiExperimentalTaskConditionsTraitement',
              text: 'Conditions de traitement',
            },
          ]
          : []), // Traitement fertilisation
        ...(this.task?.type?.uid === 'TRAITEMENTPHYTO'
          ? [
            {
              urlName: 'essaiExperimentalTaskFichePeseesPhyto',
              text: 'Fiche de pesées',
            },
            {
              urlName: 'essaiExperimentalTaskConditionsTraitement',
              text: 'Conditions de traitement',
            },
            {
              urlName: 'essaiExperimentalTaskVolumesNonEpandus',
              text: 'Volumes non épandus',
            },
          ]
          : []), // Traitement phyto
        ...(this.task?.type?.uid === 'RECOLTE'
          ? [
            {
              urlName: 'essaiExperimentalTaskConditionsRecolte',
              text: 'Conditions de récolte',
            },
          ]
          : []), // Conditions de récolte
        ...(this.task?.type?.uid === 'SEMIS'
          ? [
            {
              urlName: 'essaiExperimentalTaskFichePeseesSemis',
              text: 'Fiche de pesées',
            },
          ]
          : []), // Semis
        ...(this.task?.type?.uid === 'DESTRUCTION'
          ? [
            {
              urlName: 'essaiExperimentalTaskDestructionVegetation',
              text: 'Destruction en végétation',
            },
            {
              urlName: 'essaiExperimentalTaskDestructionRecolte',
              text: 'Destruction en récolte',
            },
            {
              urlName: 'essaiExperimentalTaskDestructionProof',
              text: 'Preuves de destruction',
            },
          ]
          : []), // Destruction
        ...(this.task?.type?.uid === 'NOTATION'
          ? [
            { urlName: 'essaiExperimentalTaskParameter', text: 'Paramètres' },
            {
              urlName: 'essaiExperimentalTaskVariableStudiedList',
              text: 'Variables étudiées',
            },
            {
              urlName: 'essaiExperimentalTaskVariableCalculated',
              text: 'Variables calculées',
            },
            { urlName: 'essaiExperimentalTaskNotationState', text: 'Etat' },
          ]
          : []), // Notation
      ]
    },
  },

  mounted() {
    this.getEssai()
    this.getExperimentalTask()
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
    setEditable(step) {
      if (step) {
        this.isEditable = ['ENPREPARATION'].includes(step)
      } else {
        this.isEditable = true
      }
    },
    getEssai() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then(
        (response) => {
          this.essai = response.data
          if (
            this.essai?.protocole?.entite?.id !== this.$store.state.auth.user.data.entite.id
          ) {
            this.$router.push({ name: 'forbidden' })
          }
        },
      )
    },
    getExperimentalTask() {
      this.fetchService
        .get(
          `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}`,
        )
        .then((reponse) => {
          this.task = reponse.data
          if (this.task.type.uid === 'NOTATION') {
            this.loadNotation()
          }
        })
    },
    loadNotation() {
      this.fetchService
        .get(
          `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`,
        )
        .then((reponseNotation) => {
          let notation = reponseNotation.data
          notation = notation.pop()
          this.fetchService
            .get(
              `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${notation.id}/reservation?sort=id.DESC&limit=1`,
            )
            .then((reponseReservation) => {
              let reservation = reponseReservation.data
              reservation = reservation.pop()
              this.setEditable(reservation?.etat?.uid)
            })
        })
    },
    realizeEvent() {
      const dateRealization = Date.parse(this.realizationDate)

      if (this.realizationDate === '') {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'La date de réalisation de la tâche expérimentale n\'a pas été renseignée.',
        })
      } else if (this.realizationDate !== '' && dateRealization > Date.now()) {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'La date de réalisation de la tâche expérimentale n\'est pas valide.',
        })
      } else {
        this.emitter.emit('open-loader')
        this.fetchService.put(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}`, {
          date_realisation: this.helperService.formatDateForApi(this.realizationDate),
        })
          .then(() => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'La date de réalisation de la tâche expérimentale a bien été modifiée.',
            })

            this.getExperimentalTask()
            this.emitter.emit('close-loader')
          })

        this.modal.realizeEvent = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.view-header__header {
  display: flex;
  gap: $gutter-half;
}
</style>

<template>
  <NavigationDropdown
    isMonochromatic
    :items-data="[
      {
        key: 'color-1',
        route: {
          name: 'essaiGeneralInformation',
          params: {
            id: $route.params.id,
          },
        },
        label: 'Infos générales',
      },
      {
        key: 'color-1',
        route: {
          name: 'essaiParcelle',
          params: {
            id: $route.params.id,
          },
        },
        label: 'Fiche parcellaire',
      },
      {
        key: 'color-1',
        route: {
          contains: 'ExperimentalTask',
          name: 'essaiCalendar',
          params: {
            id: $route.params.id,
          },
        },
        label: 'Calendrier',
      },
      {
        key: 'color-1',
        route: {
          name: 'essaiTestPlan',
          params: {
            id: $route.params.id,
          },
        },
        label: 'Plan d’essai',
      },
      {
        key: 'color-1',
        route: {
          name: 'protocol',
          params: {
            id: protocol?.id,
          },
          query:{
            from: $route.path
          }
        },
        label: 'Protocole',
      },
      {
        key: 'color-1',
        route: {
          name: 'essaiFicheBilan',
          params: {
            id: $route.params.id,
          },
        },
        label: 'Bilan',
      },
      {
        key: 'color-1',
        route: {
          name: 'essaiFiles',
          params: {
            id: $route.params.id,
          },
        },
        label: 'Fichiers',
      },
      {
        key: 'color-1',
        route: {
          name: 'essaiFixedReports',
          params: {
            id: $route.params.id,
          },
        },
        label: 'Rapports statistiques',
      },
      {
        key: 'color-1',
        route: {
          name: 'essaiImportExport',
          params: {
            id: $route.params.id,
          },
        },
        label: 'Import / Export',
      },
    ]"
  >
  </NavigationDropdown>
</template>

<script>
import NavigationDropdown from '@/components/layout/NavigationDropdown.vue'

export default {
  name: 'NavigationDropdownEssai',

  components: {
    NavigationDropdown,
  },

  props: {
    protocol: {
      type: Object,
    },
  },

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped>
</style>
